<template>
<div class="p-grid p-fluid home">
    <div class="home">
        <div class="features" style="text-align: center;background-image: url('./images/prefondo4.png');">
            <h5 style="color:#ffcc2a;">{{$t('Simulador')}}</h5>
            <h5 style="color:#ffff;">{{$t('Hazlo en SaBeé ”Protección financiera a la medida de sus necesidades”')}}</h5>

            <div class="p-grid" >
                <div class="p-col-12 p-md-9">
                    <div class="feature-card">
                        <div class="feature-card-detail">
							<br>
                            <div class="feature-name">{{$t('Información del Vehículo')}}</div>
							<div class="p-fluid p-formgrid p-grid" style="text-align: left;">
								<div class="p-field p-col-12 p-md-4">
									<label for="state">{{$t('Cliente')}}</label>
									<Dropdown v-model="vehiculo.tipocliente" :options="tipoclientes" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')"></Dropdown>
									<small v-show="validationErrors.tipocliente" class="p-error">{{$t('required')}}</small>
									<div v-if="vehiculo.tipocliente">
										<small class="p-error" v-if="vehiculo.tipocliente.code==1">{{$t('PrivCober')}}</small>										
									</div>
								</div>
								<div class="p-field p-col-12 p-md-4">
									<label for="state">{{$t('Tipo Póliza')}}</label>
									<Dropdown v-model="vehiculo.tipopoliza" :options="tipopolizas" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')"></Dropdown>
									<small v-show="validationErrors.tipopoliza" class="p-error">{{$t('required')}}</small>
								</div>
								<div class="p-field p-col-12 p-md-4">
									<label>{{$t('Fecha de nacimiento')}}</label>
									<InputMask v-model="vehiculo.fecha" placeholder="DD/MM/YYYY" slotChar="DD/MM/YYYY" mask="99/99/9999"/>
									<!--<InputText v-model="vehiculo.fecha" type="date"/>-->
									<small v-show="validationErrors.fecha" class="p-error">{{$t('required')}}</small>
								</div>
								<div class="p-field p-col-12 p-md-4">
									<label for="state">{{$t('Tipo de Vehículo')}}</label>
									<Dropdown v-model="vehiculo.tipo" :options="tipos" optionLabel="nombre" :filter="true" placeholder="Seleccionar..." v-if="i18n.locale() == 'es'">
										<template #value="slotProps">
											<div class="p-dropdown-car-value" v-if="slotProps.value">
												<span> {{slotProps.value.nombre}}</span>
											</div>
											<span v-else>
												{{slotProps.placeholder}}
											</span>
										</template>
										<template #option="slotProps">
											<div class="p-dropdown-car-option">
												<span> {{slotProps.option.nombre}}</span>
											</div>
										</template>
									</Dropdown>
									<Dropdown v-model="vehiculo.tipo" :options="tipos" optionLabel="name" :filter="true" placeholder="Select..." v-if="i18n.locale() == 'en'">
										<template #value="slotProps">
											<div class="p-dropdown-car-value" v-if="slotProps.value">
												<span> {{slotProps.value.name}}</span>
											</div>
											<span v-else>
												{{slotProps.placeholder}}
											</span>
										</template>
										<template #option="slotProps">
											<div class="p-dropdown-car-option">
												<span> {{slotProps.option.name}}</span>
											</div>
										</template>
									</Dropdown>
									<small v-show="validationErrors.tipo" class="p-error">{{$t('required')}}</small>
								</div>
								<div class="p-field p-col-12 p-md-4">
									<label for="state">{{$t('Marca')}}</label>
									<Dropdown v-model="vehiculo.marca" :options="marcas" optionLabel="nombre" :filter="true" placeholder="...?" @change="vermodelos()">
										<template #value="slotProps">
											<div class="p-dropdown-car-value" v-if="slotProps.value">
												<span> {{slotProps.value.nombre}}</span>
											</div>
											<span v-else>
												{{slotProps.placeholder}}
											</span>
										</template>
										<template #option="slotProps">
											<div class="p-dropdown-car-option">
												<span> {{slotProps.option.nombre}}</span>
											</div>
										</template>
									</Dropdown>
									<small v-show="validationErrors.marca" class="p-error">{{$t('required')}}</small>
								</div>
								<div class="p-field p-col-12 p-md-4">
									<label for="state">{{$t('Módelo')}}</label>
									<Dropdown v-model="vehiculo.modelo" :options="modelos" optionLabel="nombre" :filter="true" placeholder="...?">
										<template #value="slotProps">
											<div class="p-dropdown-car-value" v-if="slotProps.value">
												<span> {{slotProps.value.nombre}}</span>
											</div>
											<span v-else>
												{{slotProps.placeholder}}
											</span>
										</template>
										<template #option="slotProps">
											<div class="p-dropdown-car-option">
												<span> {{slotProps.option.nombre}}</span>
											</div>
										</template>
									</Dropdown>
									<small v-show="validationErrors.modelo" class="p-error">{{$t('required')}}</small>
								</div>
								<div class="p-field p-col-12 p-md-4">
									<label>{{$t('Año de Referencia')}}</label>
									<InputMask v-model="vehiculo.year" mask="9999"/>
									<small v-show="validationErrors.year" class="p-error">{{$t('required')}}</small>
								</div>
								<div class="p-field p-col-12 p-md-4">
									<label>{{$t('Nº asientos')}}</label>
									<InputNumber v-model="vehiculo.asientos" min="1" max="100" integeronly showButtons />
									<small v-show="validationErrors.asientos" class="p-error">{{$t('required')}}</small>
								</div>
								<div class="p-field p-col-12 p-md-4">
									<label>{{$t('Posición del Volante')}}</label>
									<SelectButton v-model="vehiculo.volante" :options="options" />
								</div>
							</div>
                        </div>
                    </div>
                </div>

                <div class="p-col-12 p-md-3">
                    <div class="feature-card">
                        <div class="feature-card-detail">
							<br>
                            <div class="feature-name">{{$t('Estimación')}}</div>
							<div class="p-fluid p-formgrid p-grid">
								<br><br>
								<div class="p-col-5">
									<h5><strong>{{$t('Monto')}}</strong></h5>
								</div>
								<div class="p-col-7"></div>
								
								<div class="p-col-12">
									<h5><strong>{{moneda(parseFloat(monto).toFixed(2))}}</strong></h5>
									{{$t('Duración')}}:
									<strong v-if="i18n.locale() == 'es'">12 meses</strong>
									<strong v-else>12 months</strong> 
								</div>
								<div class="p-col-12">
									<h5><strong>{{moneda(parseFloat(monto/2).toFixed(2))}}</strong></h5>
									{{$t('Duración')}}:
									<strong v-if="i18n.locale() == 'es'">6 meses</strong>
									<strong v-else>6 months</strong> 
								</div>
								<div class="p-col-12"></div>
								<br><br>
							</div>
                            <Button :label="$t('Calcular Cotización')" icon="bi bi-calculator" class="p-mr-2 p-mb-2" @click="Calcular()"></Button>
                            <p style="text-align: justify;">
								<ul>
									<li> {{$t('MenSimulador1')}}</li>
									<li> {{$t('MenSimulador2')}}</li>
									<li> <strong  @click="Registrarse"><i class="bi bi-hand-index-thumb"></i> {{$t('Regístrese')}}</strong> {{$t('o')}} <strong @click="Contacto"><i class="bi bi-hand-index-thumb"></i> {{$t('contáctenos')}}</strong> {{$t('MenSimulador3')}}</li>
								</ul>
							</p>
                        </div>
                    </div>
                </div>


			</div>
        </div>
    </div>
</div>

<Register :display="displayRegister" @aprob-login="onMenuToggle"  @close-register="close"/>

</template>

<script>
import API from "@/service/ApiConsulta";
import { useI18nPlugin } from '@unify/vuex-i18n';
import Token from "uuid-token-generator";
import url from "@/service/_URL";
import Register from '../components/Register.vue';

export default {
	components: {
        'Register': Register
    },
    data () {
        return {
            submitted: false,
            validationErrors: {},
			marcas: [],
			modelos: [],
			years: [],
			tipos: null,
			tipoclientes: null,
			vehiculo: {
				marca: null,
				modelo: null,
				compra: null,
				automatico: true,
				placa: null,
				motor: null,
				chasis: null,
				year: null,
				asientos: null,
				color: null,
				tipo: null,
				adverso: null,
				reverso: null,
				cardreg: null,
				cardemi: null,
				cardvec: null,
				volante: '<- Left',
				tipopoliza: null,
				fecha: null,
				tipocliente: null,
			},
            Dialog: false,
            size: '40vw',
			foto: false,
			img: null,
			options: ['<- Left', 'Right->'],
			tipopolizas: [],
			monto: 0,
			displayRegister: false,

        }
    },
	created() {
        if (this.$store.state.mobile){this.size = '80vw';}
		this.i18n = useI18nPlugin();
        this.url = url;
        const Consulta = new API('Simulador');
		Consulta.Ini('Simulador').then(result => {
			if (result.consult.marcas){
				this.marcas = result.consult.marcas;
			}
			if (result.consult.tipos){
				this.tipos = result.consult.tipos;
			}       
			if (result.consult.tipopolizas){
				this.tipopolizas = result.consult.tipopolizas;
			}       
			if (result.consult.tipoclientes){
				this.tipoclientes = result.consult.tipoclientes;
			}       
		}); 
		const tokgen = new Token(256);
		this.img = tokgen.generate();
	},
    methods: {
		Registrarse() {
            this.$store.commit('Mobile');
            this.displayRegister = true;
        },
		Contacto() {
            this.$router.push({ path: '/contact'});
        },
        close() {
            this.displayRegister = false;
        },
        onMenuToggle(event) {
            this.$emit('menu-toggle', event);
        },

        Calcular() {
            if (this.validateForm()) {
				this.$store.commit('Loading');
				const Consulta = new API('Simulador');
				this.vehiculo.fecha = Consulta.fdate(this.vehiculo.fecha);
				Consulta.Procesar('Simulador',{
					caso: 	'Calcular',
					vehiculo: this.vehiculo,
				}).then(response => {
					//this.$store.state.error = response;
					if (response.result){
						this.monto = response.result.monto;
					}
					this.$store.commit('Loading');	           
				});
				
            }
        },
        vermodelos() {
			if(this.vehiculo.marca.code>0){
				this.$store.commit('Loading');
				this.modelos = [];
				this.years = [];
				this.vehiculo.modelo = null;
				this.vehiculo.year = null;
				const Consulta = new API('Simulador');
				Consulta.Procesar('Simulador',{
					caso: 	'vermodelos',
					marca: this.vehiculo.marca.code,
				}).then(response => {
					//this.$store.state.error = response;
					if (response.result){
						this.modelos = response.result;
					}
					this.$store.commit('Loading');
				});
			}
        },
        veryear() {
			if(this.vehiculo.modelo.code>0){
				this.$store.commit('Loading');
				this.years = [];
				this.vehiculo.year = null;
				const Consulta = new API('Simulador');
				Consulta.Procesar('Simulador',{
					caso: 	'veryear',
					modelo: this.vehiculo.modelo.code,
				}).then(response => {
					//this.$store.state.error = response;
					if (response.result){
						this.years = response.result;
					}
					this.$store.commit('Loading');	           
				});
			}
        },
        Carnet() {
            this.Dialog = true;
        },
        moneda(number){
            return new Intl.NumberFormat("en", {style: "currency", currency:this.$store.state.currency}).format(parseFloat(number).toFixed(2));
		},
        validateForm() {
            if (!this.vehiculo.marca)
               this.validationErrors['marca'] = true;
            else
               delete this.validationErrors['marca'];
            if (!this.vehiculo.modelo)
               this.validationErrors['modelo'] = true;
            else
               delete this.validationErrors['modelo'];
            if (!this.vehiculo.tipo)
               this.validationErrors['tipo'] = true;
            else
               delete this.validationErrors['tipo'];
            if (!this.vehiculo.year)
               this.validationErrors['year'] = true;
            else
               delete this.validationErrors['year'];
            if (!this.vehiculo.asientos)
               this.validationErrors['asientos'] = true;
            else
               delete this.validationErrors['asientos'];
            if (!this.vehiculo.fecha)
               this.validationErrors['fecha'] = true;
            else
               delete this.validationErrors['fecha'];
            if (!this.vehiculo.tipopoliza)
               this.validationErrors['tipopoliza'] = true;
            else
               delete this.validationErrors['tipopoliza'];
            if (!this.vehiculo.tipocliente)
               this.validationErrors['tipocliente'] = true;
            else
               delete this.validationErrors['tipocliente'];

            return !Object.keys(this.validationErrors).length;
        }
    }
}
</script>